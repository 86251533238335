import React, { useEffect, useState } from "react";
import get from "lodash.get";
import { useContent } from "fusion-content";
import { useFusionContext, useAppContext } from "fusion-context";
import {
  styled,
  theme,
  Carousel as WPDSCarousel
} from "@washingtonpost/wpds-ui-kit";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { FeatureLayoutProvider } from "~/shared-components/FeatureLayoutContext";
import { useTableLayoutContext } from "~/shared-components/TableLayoutContext";
import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import {
  IMPLICIT_GRID_INFO,
  getLayoutVarsFromWidth
} from "~/components/layouts/utilities/grid-helpers";
import GridItem from "~/components/layouts/utilities/grid-item.jsx";
import Warning from "~/shared-components/Warning";
import WapoLazyChild from "~/shared-components/WapoLazyChild";
import { CompoundLabel } from "~/shared-components/story-card/_children/Label";

import { defaults, CarouselPropTypes } from "~/proptypes/carousel";
import {
  emptyContentConfig,
  MAX_ITEMS_TO_FETCH,
  supportedTypes,
  isAppFeed,
  getComponentData,
  getOverrides,
  getContentConfig,
  getListOfContentConfig,
  finalizeItems,
  getDedupeTally,
  sendToDataLayer
} from "./helpers";

import { getArtSlotInfo } from "~/components/features/fronts/flex-feature/utilities/index";
import { HandleFetchErrors } from "~/components/features/fronts/flex-feature/utilities/handle-fetch-errors";
import StoryCard from "~/shared-components/story-card/default";

import VideoCarousel from "./_children/VideoCarousel";
import { useChainContext } from "~/shared-components/ChainContext";
import { useRenderedContentContext } from "~/components/contexts/rendered-content-context";
import { checkIsSuppressibleDuplicate } from "~/components/utilities/handle-duplicate-content";

// NOTE: This feature uses the <StoryCard /> component like the flex feature. But unlike
// the flex feature, it is a fixed shape and it does not support content editable or menu toolbars.
// If you are using this feature as a basis for another feature that has the similar requirements,
// add the new feature's type to the neverUseMenuItems and isNeverEditable in the file:
//  ~/spartan-homepage/Root.jsx

const touchDeviceSel = "@media (hover: none)";

const footerCss = {
  display: "none",
  [touchDeviceSel]: {
    display: "flex"
  }
};

const buttonCss = {
  "&:disabled": {
    opacity: 0,
    cursor: "default"
  },
  insetBlockStart: "50%",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  padding: "6px", // to keep the buttons within the HP grid
  zIndex: 2,
  "@sm": {
    display: "unset",
    [touchDeviceSel]: {
      display: "none"
    }
  }
};

// NOTE: Not using WPDSCarousel.Item cuz it adds an outline that is
// hard to override without resorting to using !important
const WPDSCarouselItem = styled("li", {
  marginRight: theme.space["100"]
});

const Carousel = (props) => {
  const [, setActiveSlide] = useState(0);

  const { context, customFields, id, curationIndices, rootCustomFields } =
    props;
  const { artPosition } = customFields;

  const cardWidth = /(left|right)/i.test(artPosition) ? 232 : 300;

  const breakpoints = useBreakpoints();
  const bp = breakpoints.bp || IMPLICIT_GRID_INFO[0].bp;

  // NOTE: StoryCard needs to know the width in cols of the card so it
  // can use properly sized images. This is passed through the
  // FeatureLayoutProvider to help achieve that.
  const layoutVars = getLayoutVarsFromWidth({ all: cardWidth });

  const { updateItemsPerFeatureMapDQedAdjustments, featureId } =
    useTableLayoutContext();

  const { showFeatureName } = useSandwichLayoutContext();

  // START: set isAdmin
  const fusionContext = useFusionContext();
  const { isAdmin } =
    context !== undefined && context.isAdmin !== undefined
      ? context
      : fusionContext;
  // END: set isAdmin

  const { carouselType = defaults.carouselType } = customFields;

  const placeholderHeight = /vertical-video/.test(carouselType)
    ? 500
    : cardWidth;

  const overrides = getOverrides({ customFields, carouselType });
  const { carouselContentConfig } = overrides;

  // START: fetch list of content
  const listOfConfigs = getListOfContentConfig({ overrides, carouselType })
    // NOTE: rule-of-hooks safety, Part I -- Filter length to MAX_ITEMS_TO_FETCH
    .filter((_, i) => i < MAX_ITEMS_TO_FETCH);

  const listOfConfigsLength = listOfConfigs.length;

  // NOTE: rule-of-hooks safety, Part II -- Fill length to MAX_ITEMS_TO_FETCH
  if (listOfConfigs.length < MAX_ITEMS_TO_FETCH) {
    listOfConfigs.push(
      ...new Array(MAX_ITEMS_TO_FETCH - listOfConfigs.length).fill(
        emptyContentConfig
      )
    );
  }
  // NOTE: listOfData will be the same length as listOfConfigs. Needed to issue invalid content warnings
  const listOfData = listOfConfigs.map((config) => {
    // NOTE: Safely disabling rule-of-hooks (see above)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let data = useContent(config);
    data = config === emptyContentConfig ? undefined : data;
    return data;
  });
  // NOTE: listOfContent filters out content that couldn't be fetched
  const initListOfContent = listOfData.filter((_) => !!_);
  const [listOfContent, setListOfContent] = useState([...initListOfContent]);
  // END: fetch list of content

  // START: fetch backing content
  const initContent = useContent(
    listOfConfigsLength < MAX_ITEMS_TO_FETCH
      ? getContentConfig(carouselContentConfig, carouselType)
      : emptyContentConfig
  );
  const initContentItems = get(initContent, "items", []);
  const [contentItems, setContentItems] = useState([...initContentItems]);
  // END: fetch backing content

  const areAnyLoading = initListOfContent
    .map((data) => get(data, "isLoading", false))
    .some((_) => _);

  const isLoading = get(initContent, "isLoading", false) || areAnyLoading;
  // NOTE: Needed for client-side content sources
  const [isReadyToUpdate, setReadyToUpdate] = useState(!isLoading);

  const { disableLazyLoading } = useChainContext();

  // START: Handle suppressible duplicates
  const {
    renderedContent,
    isDifferentListOfRenderedContent,
    UpdateRenderedContentInChainCtx
  } = useRenderedContentContext();
  const chainOverrides = useChainContext();
  const { useDesktopOrdering } = chainOverrides;

  // NOTE: Combining, deduping, enforcing max length (MAX_ITEMS as opposed to MAX_ITEMS_TO_FETCH)
  const items = finalizeItems({
    listOfContent,
    contentItems,
    carouselType,
    overrides
  });

  // NOTE: Useful for warning message
  const dedupeTally = getDedupeTally(listOfContent, contentItems, items);
  const dedupeMsg = dedupeTally
    ? ` ADDITIONAL INFO: ${dedupeTally} stories have been deduped.`
    : "";

  const { siteProperties } = useAppContext();

  const appFeed = isAppFeed(carouselType);

  useEffect(() => {
    if (!isLoading) {
      setReadyToUpdate(true);
      const filterDuplicates = (item) => {
        const { desktopInfo, mobileInfo } = checkIsSuppressibleDuplicate({
          renderedContent,
          rootCustomFields,
          chainOverrides,
          curationIndices,
          content: item,
          // featureId: id,
          overrides
        });
        return !(useDesktopOrdering ? desktopInfo : mobileInfo)
          .isSuppressibleDuplicate;
      };

      const nextListOfContent = initListOfContent?.filter(filterDuplicates);
      setListOfContent((prev) => {
        if (isDifferentListOfRenderedContent(nextListOfContent, prev)) {
          return nextListOfContent;
        }
        return prev;
      });

      const nextContentItems = initContentItems?.filter(filterDuplicates);
      setContentItems((prev) => {
        if (isDifferentListOfRenderedContent(nextContentItems, prev)) {
          return nextContentItems;
        }
        return prev;
      });
    }
  }, [
    isLoading,
    isDifferentListOfRenderedContent,
    useDesktopOrdering,
    renderedContent,
    rootCustomFields,
    chainOverrides,
    curationIndices,
    // featureId: id,
    initListOfContent,
    initContentItems,
    overrides
  ]);

  useEffect(() => {
    // NOTE: In the admin, don't let the items per feature go 0 otherwise
    // the feature won't come back unless the admin is reloaded
    const adjustment = isAdmin || items.length > 0 ? 0 : -1;
    if (isReadyToUpdate && updateItemsPerFeatureMapDQedAdjustments) {
      updateItemsPerFeatureMapDQedAdjustments(featureId, adjustment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyToUpdate, featureId, items]);

  // NOTE: Abort if not a supported carouselType
  if (!supportedTypes.includes(carouselType)) return null;
  // NOTE: Abort if no items on published page
  if (!items.length && !isAdmin) return null;
  // NOTE: Abort if app only carousel type
  if (appFeed && !isAdmin) return null;

  const { label, cta } = getComponentData({ overrides, isAdmin });

  const propsList = items.map((item, i) => ({
    index: i,
    id,
    overrides,
    curationIndices,
    content: !/(.+)-live-graphic$/.test(carouselType) ? item : undefined,
    isFeed: true,
    isAdmin
  }));

  return (
    <GridItem
      overrides={overrides}
      featureLabel="Carousel"
      placementRequirements={{
        inMultiTableChain: true,
        cols: { min: 5 },
        inAppOnly: appFeed
      }}
    >
      {items.map((item, i) => (
        <UpdateRenderedContentInChainCtx
          key={item?._id || i}
          {...propsList[i]}
        />
      ))}
      <WapoLazyChild
        eager={disableLazyLoading || siteProperties.disableLazyLoading}
        renderPlaceholder={(ref) => (
          <div style={{ height: `${placeholderHeight}px` }} ref={ref} />
        )}
      >
        {showFeatureName && (
          <span
            style={{ marginBottom: "8px", display: "block", opacity: 0.75 }}
          >
            {overrides?.displayName || "fronts/carousel"}
          </span>
        )}
        {isAdmin && (
          <HandleFetchErrors
            lists={{ carousel: { listOfConfigs, listOfData } }}
          />
        )}
        {label && (
          <div className="mb-xs">
            <CompoundLabel {...label} />
          </div>
        )}
        {!appFeed && !!items.length && (
          <FeatureLayoutProvider key={items.length} value={{ layoutVars }}>
            {carouselType === "vertical-video" ? (
              <VideoCarousel items={items} />
            ) : (
              <WPDSCarousel.Root
                onPageChange={(index) => {
                  setActiveSlide((prev) => {
                    sendToDataLayer(prev, index);
                    return index;
                  });
                }}
                css={{ position: "relative" }}
              >
                <WPDSCarousel.PreviousButton
                  css={{ ...buttonCss, insetInlineStart: "0%" }}
                  variant="secondary"
                />
                <WPDSCarousel.NextButton
                  css={{ ...buttonCss, insetInlineStart: "100%" }}
                  variant="secondary"
                />
                <WPDSCarousel.Content
                  onKeyDown={function noRefCheck() {}}
                  onKeyUp={function noRefCheck() {}}
                >
                  {items.map((item, i) => (
                    <WPDSCarouselItem key={item?._id || i}>
                      <StoryCard
                        {...propsList[i]}
                        artSlot={getArtSlotInfo({
                          content: item,
                          overrides
                        })}
                        electionData={
                          /^elex-live-graphic$/.test(carouselType)
                            ? item
                            : undefined
                        }
                        style={{
                          width: `${cardWidth}px`,
                          backgroundSize: "contain"
                        }}
                      />
                    </WPDSCarouselItem>
                  ))}
                </WPDSCarousel.Content>
                {/xs/.test(bp) && (
                  <WPDSCarousel.Footer css={footerCss}>
                    <WPDSCarousel.Dots />
                  </WPDSCarousel.Footer>
                )}
              </WPDSCarousel.Root>
            )}
          </FeatureLayoutProvider>
        )}
        {!appFeed && !items.length && (
          <Warning
            message={`No content in this Carousel. Add content or remove the feature. Supported types are: ${supportedTypes.join(
              ", "
            )}.${dedupeMsg}`}
            level="warning"
          />
        )}
        {appFeed && (
          <Warning
            message={`This Carousel type, "${carouselType}", appears only in the app. No content will appear in the Assembler admin. Make sure this feature is set to “no content”.`}
            level="info"
          />
        )}
        {/* TODO: WFO-5658; 13px here + 3px from carousel gives the desired 16px */}
        {cta && (
          <div
            style={{ marginTop: theme.space["100"] }}
            className="card-bottom"
          >
            <CompoundLabel {...cta} />
          </div>
        )}
      </WapoLazyChild>
    </GridItem>
  );
};

Carousel.label = "Carousel";
Carousel.propTypes = CarouselPropTypes;

export default Carousel;
